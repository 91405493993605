.horizontal-scroll {
    position: sticky;
    overflow: hidden; /* Ensures images don't exceed the container bounds *//* Full width of the screen */
}

.icon-container {
    display: flex;
    animation: scrollAnimation 25s linear infinite;
    width: calc(100% + 440px); /* Adjust this dynamically */
}

@keyframes scrollAnimation {
    0% {
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(-100%);
    }
}


@media (min-width: 320px) {
    .icon-container img {
        width: 100px; /* Adjust for medium screens */
    }
}

@media (min-width: 640px) {
    .icon-container img {
        width: 120px; /* Adjust for medium screens */
    }
}

@media (min-width: 768px) {
    .icon-container img {
        width: 150px; /* Adjust for large screens */
    }
}

@media (min-width: 1024px) {
    .icon-container img  {
        width: 200px;
        /* Adjust for extra-large screens */
    }
}

.rotating-elements {
    position: absolute;
    animation: rotateForwards 7s linear infinite;
}

@keyframes rotateForwards {
    0% {
        transform: rotate(310deg);
    }
    50% {
        transform: rotate(450deg);
    }
    100% {
        transform: rotate(310deg);
    }
}

.rotating-element {
    position: absolute;
    animation: rotateForward 7s linear infinite;
}

@keyframes rotateForward {
    0% {
        transform: rotate(320deg);
    }
    50% {
        transform: rotate(445deg);
    }
    100% {
        transform: rotate(320deg);
    }
}